<script>
export default {
  name: "ETable",
  props: {
    columns: {
      type: Array,
      require: true,
    },
    data: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {};
  },

  methods: {
    renderScopedSlot(propName, scopedSlots) {
      //  说明是column自带的选择框或者序号等
      if (!propName) {
        return;
      }
      
      //  默认的模板
      const tmp = {
        default: ({ row }) => <span>{row[propName]}</span>,
      };
      
      //  有提供就用提供的模板
      if (scopedSlots[propName]) {
        tmp.default = scopedSlots[propName];
      }
      if (scopedSlots[`${propName}-header`]) {
        tmp.header = scopedSlots[`${propName}-header`]
      }
      return tmp;
    },
  },

  render() {
    const {
      $attrs,
      $listeners,
      $scopedSlots,
      data,
      columns,
      renderScopedSlot,
    } = this;
    
    //  兼容el-table的api和事件
    const tableProps = {
      on: $listeners,
      attrs: $attrs,
    };

    return (
      <div class="e-table">
        <el-table data={data} {...tableProps}>
          {columns.map(({ label, prop, ...otherProps }) => (
            <el-table-column
              key={label}
              label={label}
              scopedSlots={renderScopedSlot(prop, $scopedSlots)}
              prop={prop}
              {...{
                props: { ...otherProps },
              }}
            ></el-table-column>
          ))}
        </el-table>
      </div>
    );
  },
};

</script>