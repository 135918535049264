
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class Resize extends Vue {
  tableMaxheight = 0;
  tempSurplusHeight = 100;

  windowResize(h: number  = 100) {
    this.tempSurplusHeight = h;
    this.$nextTick(() => {
        this.setTableMaxheight();
    })
    window.onresize = () => {
        return (
            this.setTableMaxheight
        )()
      }
  }
  setTableMaxheight() {
    this.tableMaxheight = window.innerHeight - this.tempSurplusHeight;
  }
}