import { service } from '@/ajax/request'

export const detectionSummaryCount = () => (
  service({
    url: '/detection/summary/tongue',
    method: 'POST',
  })
)

export const detectionSummary = (params: any) => (
  service({
    url: '/detection/summary/saveLog',
    method: 'POST',
    params,
  })
)

export const detectionSummaryList = (params: any) => (
  service({
    url: '/detection/summary/query',
    method: 'POST',
    params,
  })
)

export const detectionSummaryLog = (params: any) => (
  service({
    url: '/detection/summary/queryLog',
    method: 'POST',
    params,
  })
)

export const detectionSummaryDetail = (memberId) => (
  service({
    url: `/detection/summary/detail?memberId=${memberId}`,
    method: 'POST',
  })
)

// 检测记录
export const userMemberDetectionList = (params) => (
  service({
    url: `/user/userMemberDetection/queryUserDetection`,
    method: 'POST',
    params,
  })
)
// 调理记录
export const userMemberRecuperateList = (params) => (
  service({
    url: `/pms/pmsProductExpend/query`,
    method: 'POST',
    params,
  })
)
// 设备分类
export const deviceClassTypeList = (parentId) => (
  service({
    url: `/DeviceClassType/deviceClassType/loadByType/${parentId}`,
    method: 'GET',
  })
)

